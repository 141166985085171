<template>
  <div>
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <div class="block_3 flex-row">
          <div class="text-group_17 flex-col">
            <span class="text_1">标杆案例</span>
            <span class="text_2"
              >建筑能源管理领域，<br />数字化转型成功案例</span
            >
          </div>
        </div>
        <div class="block_4 flex-row"><div class="block_1 flex-col"></div></div>
      </div>
    </div>

    <div class="casetype_list_box">
      <div
        class="casetype_item_box"
        :class="caseType == item.value ? '' : 'no_selected'"
        @click="chooseCasetype(item.value)"
        v-for="item in caseTypeList"
        :key="item.value"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="list_3 flex-col">
      <div class="item_box" v-for="(item, index) in showList" :key="index">
        <img class="image_2" referrerpolicy="no-referrer" :src="item.value" />
        <div class="text-group_18 flex-col">
          <div class="text_10" v-html="item.casetype"></div>
          <div class="text_11" v-html="item.label"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import test from "./assets/service/project1.png";

export default {
  data() {
    return {
      caseTypeList: [
        {
          label: "全部",
          value: "all",
          choose: true,
        },
        {
          label: "商业综合体",
          value: "syzht",
          choose: false,
        },
        {
          label: "教育医疗",
          value: "jyyl",
          choose: false,
        },
        {
          label: "智慧园区",
          value: "zhyq",
          choose: false,
        },
        {
          label: "酒店公寓",
          value: "jdgy",
          choose: false,
        },
        {
          label: "工矿企业",
          value: "gkqy",
          choose: false,
        },
        {
          label: "光伏储能",
          value: "gfcn",
          choose: false,
        },
      ],
      caseType: "all",
      caseObj: {
        all: [
          {
            value: test,
            casetype: "商业综合体",
            label: "嘉兴老佛爷购物中心项目",
          },
        ],
        syzht: [
          {
            value: test,
            casetype: "商业综合体",
            label: "综合能源管理系统",
          },
        ],
        jyyl: [
          {
            value: test,
            casetype: "商业综合体",
            label: "光伏发电监控系统",
          },
        ],
        rjhjjc: [],
        rjlkjzm: [],
      },
    };
  },
  mounted() {
    this.getIndexInfo();
    if (JSON.stringify(this.$route.params) != "{}") {
      let temptype = sessionStorage.getItem("caseType");
      this.caseType = temptype || this.$route.params.caseType;
      sessionStorage.removeItem("caseType");
    }
  },
  computed: {
    showList() {
      return this.caseObj[this.caseType];
    },
  },
  methods: {
    async getIndexInfo() {
      const res1 = await this.axios.get("/jsondata/fwallist.json");
      this.caseObj = res1.data;
      let { syzht, gkqy, jyyl, jdgy, zhyq, gfcn } = res1.data;
      this.caseObj.all = [
        ...syzht,
        ...gkqy,
        ...jyyl,
        ...jdgy,
        ...zhyq,
        ...gfcn,
      ];
    },
    chooseCasetype(val) {
      this.caseType = val;
      sessionStorage.setItem("caseType", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.group_1 {
  background-image: url(./assets/product/product_center.png);
  border: 1px solid rgba(151, 151, 151, 1);
}

.group_2 {
  background-color: rgba(24, 59, 124, 0.4);
  padding: 3.2vw 4vw 15.2vw 4vw;
}

.block_3 {
  margin: 16.8vw 5.6vw 0 0;
}

.text_1 {
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 4.26vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-right: 69.34vw;
}

.text_2 {
  width: 86.4vw;
  height: 17.6vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 8.8vw;
  margin-top: 1.34vw;
}

.block_4 {
  margin: 2.93vw 68vw 0 0;
}

.block_1 {
  background-color: rgba(255, 123, 52, 1);
  width: 24vw;
  height: 0.54vw;
}

.casetype_list_box {
  display: flex;
  width: 92vw;
  margin: 5vw auto;
  flex-wrap: wrap;
  .casetype_item_box {
    height: 10vw;
    min-width: 25vw;
    padding: 0 2vw;
    margin: 0 2vw 2vw 0;
    color: #fff;
    background-color: rgba(52, 57, 192, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: ;
  }
  .no_selected {
    background-color: transparent;
    color: #222222;
  }
}

// 列表项
.list_3 {
  width: 92vw;
  margin: 0 auto;
  margin-top: 4vw;
}

.item_box {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.45);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 92vw;
  border: 1px solid rgba(244, 244, 244, 1);
  margin-bottom: 4vw;
}

.image_2 {
  width: 100%;
}

.text-group_18 {
  margin: 5.06vw 0vw 0 6.4vw;
}

.text_10 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.2vw;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.54vw;
  margin-right: 10.67vw;
}

.text_11 {
  width: 52.54vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  font-family: AlibabaPuHuiTi-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-top: 0.8vw;
  margin-bottom: 6vw;
}
</style>