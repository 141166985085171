<template>
  <div class="content">
    <!-- 大图片 -->
    <div class="group_1 flex-col">
      <span class="text_10">扑沃科技</span>
      <span class="text_11">成为智能科技的创新者和引领者</span>
      <div class="box_2 flex-col"></div>
    </div>

    <div class="intro_box">
      <div class="text-group_13 flex-col justify-between">
        <span class="text_12">上海扑沃智能科技有限公司</span>
        <span class="text_13"
          >Shanghai&nbsp;Puwo&nbsp;Intelligent&nbsp;Technology&nbsp;Co.,&nbsp;Ltd</span
        >
      </div>

      <div class="text-group_14 flex-col justify-between">
        <span class="text_14"
          >敢为人先，追求卓越，以创新为动力，以质量谋发展</span
        >
        <span class="paragraph_1">
          随着我国经济社会发展不断深入,生态文明建设地位和作用日益凸显。建设生态文明是关系人民福祉、关乎民族未来的大计,走向生态文明新时代、建设美丽中国是实现中华民族伟大复兴的中国梦的重要内容。
          <br />
          坚持人与自然和谐共生，必须树立和践行“绿水青山就是金山银山”的理念,坚持节约资源和保护环境的基本国策。
          <br />
          扑沃科技争做中国能源管理系统领先品牌，&nbsp;敢为人先，追求卓越，以创新为动力，以质量谋发展，服务“碳达峰、碳中和”战略决策，&nbsp;共筑中国梦-绿色化，扑沃将用科技的力量，还原自然的生态环境。
          <br />
          公共建筑能耗监测系统领域的高新技术企业，致力于提供能耗监测系统解决方案、相关产品及技术服务。&nbsp;具备专业的技术团队及多年的项目经验，具有强大的研发生产、销售及系统集成能力。&nbsp;产品涵盖了PWT-EMS系列能耗监测系统软件、PWT-DG系列物联网关(数据采集设备)，以及各类智能电力仪表、传感器。
        </span>
      </div>
    </div>

    <div class="service_body">
      <!-- 荣誉资质 -->
      <div class="self_card" id="ryzz_box">
        <div class="group_19 flex-row justify-between">
          <div class="section_3 flex-col"></div>
          <div class="text-wrapper_33 flex-col justify-between">
            <span class="text_61">资质证书</span>
            <span class="text_62">certificate</span>
          </div>
        </div>
        <div class="card_body_zs">
          <div class="zs_imgs">
            <ul>
              <li>
                <img src="../assets/image/zs/DDS1852_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/DDS1852Y_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/DDS1852Y检测报告.jpg" alt="" />
              </li>
              <li>
                <img
                  src="../assets/image/zs/DTS1852、PWT803.830检测报告.jpg"
                  alt=""
                />
              </li>
              <li>
                <img src="../assets/image/zs/DTS1852_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/DTS1852Y_00.jpg" alt="" />
              </li>
              <li>
                <img
                  src="../assets/image/zs/ISO质量管理体系认证证书.jpg"
                  alt=""
                />
              </li>
              <li>
                <img src="../assets/image/zs/PWD800_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWD800Y_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT810A_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT810V_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT830_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT830A_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT830V_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/PWT950_00.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/高企业证书.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/软著2023-1.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/软著2023-2.jpg" alt="" />
              </li>
              <!-- <li>
                <img src="../assets/image/zs/软著2023-3.jpg" alt="" />
              </li>
              <li>
                <img
                  src="../assets/image/zs/rz/扑沃PWT-CMS集控运维管理软件V2.0.jpg"
                  alt=""
                />
              </li> -->
              <!-- <li>
                <img src="../assets/image/zs/网关检测报告.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/网关检测报告-2.jpg" alt="" />
              </li>
              <li>
                <img src="../assets/image/zs/营业执照.jpg" alt="" />
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="culture_box">
      <div class="box_11 flex-col">
        <div class="group_17 flex-row justify-between">
          <div class="group_8 flex-col"></div>
          <div class="text-wrapper_25 flex-col justify-between">
            <span class="text_33">企业文化</span>
            <span class="text_34">corporate&nbsp;culture</span>
          </div>
        </div>
        <div class="text-wrapper_26 flex-row justify-between">
          <div
            style="margin-left: -1.57vw"
            v-for="(item, index) in titleList"
            :key="index"
          >
            <span
              class="text_37"
              @click="chooseCul(item.value)"
              :class="curCulture == item.value ? 'selected' : ''"
              >{{ item.label }}</span
            >
            <span v-show="item.value != 'zz'" class="text_36">/</span>
          </div>
        </div>
        <div class="text-wrapper_9 flex-col">
          <span class="text_42">{{ cultureObj[curCulture].title }}</span>
          <span class="text_43">{{ cultureObj[curCulture].des }}</span>
        </div>
      </div>
    </div>

    <div class="contactus_box">
      <div class="group_18 flex-row justify-between">
        <div class="group_7 flex-col"></div>
        <div class="text-wrapper_27 flex-col justify-between">
          <span class="text_26">联系我们</span>
          <span class="text_27">Contact&nbsp;Us</span>
        </div>
      </div>

      <div class="box_9 flex-row">
        <div class="box_25 flex-col justify-between">
          <div class="text-wrapper_28 flex-col">
            <span class="text_72">上海扑沃智能科技有限公司</span>
          </div>
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="../assets/companyintro/companyintro6.png"
          />
        </div>
        <div class="text-wrapper_4 flex-col">
          <span class="paragraph_2">
            联系电话：
            <br />
            021-39882683
            <br />
            <br />
            全国热线：
            <br />
            17721323870
            <br />
            <br />
            江浙沪热线：
            <br />
            13918812305
            <br />
            <br />
            公司邮箱：
            <br />
            puwo888&#64;163.com
            <br />
            <br />
            公司地址：
            <br />
            上海市奉贤区浦星公路9688弄6号6幢二层
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curCulture: "sm",
      titleList: [
        {
          label: "使命",
          value: "sm",
        },
        {
          label: "愿景",
          value: "yj",
        },
        {
          label: "价值观",
          value: "jzg",
        },
        {
          label: "宗旨",
          value: "zz",
        },
      ],
      cultureObj: {
        sm: {
          title: "我们的使命",
          des: "改善人类智能生活、推动科技发展、让生活更美好",
        },
        yj: {
          title: "我们的愿景",
          des: "成为智能科技的创新者和引领者",
        },
        jzg: {
          title: "我们的价值观",
          des: "诚信、责任、尊重、和谐、团队",
        },
        zz: {
          title: "我们的宗旨",
          des: "诚信经营，以客户为中心",
        },
      },
    };
  },
  mounted() {
    // this.getIndexInfo();
  },

  methods: {
    async getIndexInfo() {
      const res1 = await this.axios.get("/jsondata/fwallist.json");
      this.syzhtList = res1.data.syzht;
    },

    chooseCul(val) {
      this.curCulture = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
}


.service_body {
  width: 100vw;
  padding-top: 1vw;
  background-image: url(../assets/companyintro/map.png);
  .self_card {
    width: 75vw;
    border-radius: 6px;
    margin: 1rem auto;
    .card_title_box {
      width: 100%;
      height: 5rem;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1.5rem;
      .card_title {
        display: inline-block;
        height: 4.8rem;
        line-height: 4.8rem;
        border-bottom: 4px solid #0a79d4;
        font-size: 36px;
      }
    }

    .card_body {
      height: 500px;
      width: 100%;
      .text_cls {
        text-indent: 2em;
        line-height: 1.5;
        color: #333;
        letter-spacing: 0.1em;
      }
    }
  }
}

.card_body_zs {
  width: 100%;

  .zs_imgs {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        list-style: none;
        height: 100%;
        width: 10.94vw;
        cursor: pointer;
        // border: 4px solid #f1f1f1;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        box-sizing: border-box;
        margin-bottom: 1%;
        padding: 4px 0;
        img {
          width: 8.5938vw;
          height: 12.1875vw;
          object-fit: cover;
          object-position: center;
          box-shadow: 0 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
}

// 新版css
.group_1 {
  background-image: url(../assets/companyintro/companyintrobg.png);
  width: 100vw;
  height: 24.9vw;
}

.text_10 {
  width: 6.67vw;
  height: 2.35vw;
  overflow-wrap: break-word;
  color: rgba(154, 161, 173, 1);
  font-size: 1.66vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.35vw;
  margin: 8.32vw 0 0 12.5vw;
}

.text_11 {
  width: 33.03vw;
  height: 3.29vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.39vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 3.29vw;
  margin: 0.36vw 0 0 12.5vw;
}

.box_2 {
  background-color: rgba(255, 123, 52, 1);
  width: 9.38vw;
  height: 0.21vw;
  margin: 1.35vw 0 8.33vw 12.5vw;
}

.intro_box {
  .text-group_13 {
    height: 3.29vw;
    margin: 2.81vw 0 0 12.5vw;
  }

  .text_12 {
    width: 16.67vw;
    height: 1.93vw;
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 1.35vw;
    letter-spacing: 0.6499999761581421px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 1.93vw;
  }

  .text_13 {
    width: 20.32vw;
    height: 0.99vw;
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 0.99vw;
    margin-top: 0.37vw;
  }

  .text-group_14 {
    width: 75vw;
    margin: 2.29vw auto;
  }

  .text_14 {
    height: 1.46vw;
    overflow-wrap: break-word;
    color: rgba(52, 57, 192, 1);
    font-size: 1.04vw;
    letter-spacing: 0.5px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.46vw;
  }

  .paragraph_1 {
    height: 9.59vw;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 0.83vw;
    font-weight: NaN;
    text-align: left;
    line-height: 1.5vw;
    margin-top: 0.79vw;
    letter-spacing: 0.1vw;
  }
}

.group_19 {
  width: 12.56vw;
  height: 3.91vw;
  margin: 4vw 0 1.5vw 0;
}

.section_3 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_33 {
  width: 11.41vw;
  height: 3.91vw;
}

.text_61 {
  width: 5.16vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_62 {
  width: 11.41vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}

.culture_box {
  width: 100vw;
  .box_11 {
    background-image: url(../assets/companyintro/companyintrobg2.png);
    width: 100vw;
    height: 31.25vw;
  }

  .group_17 {
    width: 21.25vw;
    height: 3.91vw;
    margin: 4.68vw 0 0 12.5vw;
  }

  .group_8 {
    background-color: rgba(52, 57, 192, 1);
    width: 0.21vw;
    height: 3.44vw;
    margin-top: 0.16vw;
  }

  .text-wrapper_25 {
    width: 20.11vw;
    height: 3.91vw;
  }

  .text_33 {
    width: 5.16vw;
    height: 1.72vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.25vw;
    letter-spacing: 0.6000000238418579px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 1.72vw;
  }

  .text_34 {
    width: 20.11vw;
    height: 2.19vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.77vw;
    text-transform: uppercase;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 2.19vw;
  }

  .text-wrapper_26 {
    width: 19.22vw;
    height: 1.31vw;
    margin: 2.91vw 0 0 12.5vw;
  }


  .text_36 {
    width: 0.47vw;
    height: 1.31vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.93vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.31vw;
    margin-left: 1.57vw;
  }

  .text_37 {
    width: 1.88vw;
    height: 1.31vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.93vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.31vw;
    margin-left: 1.57vw;
    cursor: pointer;
  }
  .selected {
    color: rgba(255, 123, 52, 1);
  }

  .text-wrapper_9 {
    width: 37.5vw;
    height: 10.06vw;
    background: linear-gradient(
      309deg,
      rgba(255, 255, 255, 0.4) 0%,
      #ffffff 100%
    );
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    justify-content: flex-center;
    margin: 2.29vw 0 6.09vw 12.5vw;
  }

  .text_42 {
    width: 5.21vw;
    height: 1.46vw;
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 1.04vw;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.46vw;
    margin: 2.81vw 0 0 2.44vw;
  }

  .text_43 {
    width: 32.09vw;
    height: 2.09vw;
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 1.45vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 2.09vw;
    margin: 0.88vw 0 2.81vw 2.44vw;
  }
}

.contactus_box {
  padding-bottom: 3vw;
  .group_18 {
    width: 12.66vw;
    height: 3.91vw;
    margin: 3.47vw 0 0 12.5vw;
  }

  .group_7 {
    background-color: rgba(52, 57, 192, 1);
    width: 0.21vw;
    height: 3.44vw;
    margin-top: 0.16vw;
  }

  .text-wrapper_27 {
    width: 11.52vw;
    height: 3.91vw;
  }

  .text_26 {
    width: 5.16vw;
    height: 1.72vw;
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 1.25vw;
    letter-spacing: 0.6000000238418579px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 1.72vw;
  }

  .text_27 {
    width: 11.52vw;
    height: 2.19vw;
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 1.77vw;
    text-transform: uppercase;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 2.19vw;
  }

  .box_9 {
    border-radius: 4px;
    background-image: url(../assets/companyintro/companyintro4.png);
    width: 75vw;
    height: 26.57vw;
    margin: 2.29vw 0 0 12.5vw;
  }

  .box_25 {
    width: 12.71vw;
    height: 4.85vw;
    margin: 8.9vw 0 0 12.5vw;
  }

  .text-wrapper_28 {
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 22px;
    height: 2.3vw;
    width: 12.71vw;
  }

  .text_72 {
    width: 10.27vw;
    height: 1.15vw;
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 1.15vw;
    margin: 0.57vw 0 0 0.93vw;
  }

  .label_3 {
    width: 1.52vw;
    height: 2.35vw;
    margin: 0.2vw 0 0 5.62vw;
  }

  .text-wrapper_4 {
    height: 21.36vw;
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    backdrop-filter: blur(10px);
    width: 29.59vw;
    margin: 2.6vw 1.51vw 0 18.69vw;
  }

  .paragraph_2 {
    width: 21.31vw;
    height: 16.98vw;
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 0.93vw;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    line-height: 1.15vw;
    margin: 2.18vw 0 0 2.91vw;
  }
}
</style>
