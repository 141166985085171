<template>
  <div>
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <div class="block_3 flex-row">
          <div class="text-group_17 flex-col">
            <span class="text_1">扑沃科技</span>
            <span class="text_2">成为智能科技的创新者和引领者</span>
          </div>
        </div>
        <div class="block_4 flex-row"><div class="block_1 flex-col"></div></div>
      </div>
    </div>

    <div class="intro_box">
      <div class="text-group_13 flex-col justify-between">
        <span class="text_12">上海扑沃智能科技有限公司</span>
        <span class="text_13"
          >Shanghai&nbsp;Puwo&nbsp;Intelligent&nbsp;Technology&nbsp;Co.,&nbsp;Ltd</span
        >
      </div>
      <div class="text-group_14 flex-col justify-between">
        <span class="futitle">
          敢为人先，追求卓越，
          <br />
          以创新为动力，以质量谋发展
        </span>
        <span class="paragraph_1">
          随着我国经济社会发展不断深入,生态文明建设地位和作用日益凸显。建设生态文明是关系人民福祉、关乎民族未来的大计,走向生态文明新时代、建设美丽中国是实现中华民族伟大复兴的中国梦的重要内容。
          <br />
          坚持人与自然和谐共生，必须树立和践行“绿水青山就是金山银山”的理念,坚持节约资源和保护环境的基本国策。
          <br />
          扑沃科技争做中国能源管理系统领先品牌，&nbsp;敢为人先，追求卓越，以创新为动力，以质量谋发展，服务“碳达峰、碳中和”战略决策，&nbsp;共筑中国梦-绿色化，扑沃将用科技的力量，还原自然的生态环境。
          <br />
          公共建筑能耗监测系统领域的高新技术企业，致力于提供能耗监测系统解决方案、相关产品及技术服务。&nbsp;具备专业的技术团队及多年的项目经验，具有强大的研发生产、销售及系统集成能力。&nbsp;产品涵盖了PWT-EMS系列能耗监测系统软件、PWT-DG系列物联网关(数据采集设备)，以及各类智能电力仪表、传感器。
        </span>
      </div>
    </div>
    <div class="zs_bg_box">
      <div class="zs_box">
        <div class="section_5 flex-row justify-between">
          <div class="block_1 flex-col"></div>
          <div class="text-group_27 flex-col">
            <span class="text_15">资质证书</span>
            <span class="text_16">certificate</span>
          </div>
        </div>
        <div class="zs_imgs">
          <ul>
            <li>
              <img src="./assets/aboutus/DDS1852_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/DDS1852Y_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/DDS1852Y检测报告.jpg" alt="" />
            </li>
            <li>
              <img
                src="./assets/aboutus/DTS1852、PWT803.830检测报告.jpg"
                alt=""
              />
            </li>
            <li>
              <img src="./assets/aboutus/DTS1852_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/DTS1852Y_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/ISO质量管理体系认证证书.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWD800_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWD800Y_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT810A_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT810V_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT830_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT830A_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT830V_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/PWT950_00.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/高企业证书.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/软著2023-1.jpg" alt="" />
            </li>
            <li>
              <img src="./assets/aboutus/软著2023-2.jpg" alt="" />
            </li>
            <!-- <li>
                  <img src="./assets/aboutus/软著2023-3.jpg" alt="" />
                </li>
                <li>
                  <img
                    src="./assets/aboutus/rz/扑沃PWT-CMS集控运维管理软件V2.0.jpg"
                    alt=""
                  />
                </li> -->
            <!-- <li>
                  <img src="./assets/aboutus/网关检测报告.jpg" alt="" />
                </li>
                <li>
                  <img src="./assets/aboutus/网关检测报告-2.jpg" alt="" />
                </li>
                <li>
                  <img src="./assets/aboutus/营业执照.jpg" alt="" />
                </li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="culture_box">
      <div class="box_11 flex-col">
        <div class="group_17 flex-row justify-between">
          <div class="group_8 flex-col"></div>
          <div class="text-wrapper_25 flex-col justify-between">
            <span class="text_33">企业文化</span>
            <span class="text_34">corporate&nbsp;culture</span>
          </div>
        </div>
        <div class="text-wrapper_26 flex-row justify-between">
          <div
            style="margin-left: -1.57vw"
            v-for="(item, index) in titleList"
            :key="index"
          >
            <span
              class="text_37"
              @click="chooseCul(item.value)"
              :class="curCulture == item.value ? 'selected' : ''"
              >{{ item.label }}</span
            >
            <span v-show="item.value != 'zz'" class="text_36">/</span>
          </div>
        </div>
        <div class="text-wrapper_9 flex-col">
          <span class="text_42">{{ cultureObj[curCulture].title }}</span>
          <span class="text_43">{{ cultureObj[curCulture].des }}</span>
        </div>
      </div>
    </div>

    <div class="contactus_box">
      <div class="group_23 flex-col">
        <div class="group_24 flex-row justify-between">
          <div class="section_2 flex-col"></div>
          <div class="text-group_30 flex-col">
            <span class="text_28">联系我们</span>
            <span class="text_29">Contact&nbsp;Us</span>
          </div>
        </div>
      </div>
      <div class="group_14 flex-col">
        <div class="text-wrapper_6 flex-col">
          <span class="text_30">上海扑沃智能科技有限公司</span>
        </div>
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="./assets/aboutus/aboutusicon.png"
        />
      </div>

      <div class="text-wrapper_11 flex-col">
        <span class="paragraph_4">
          联系电话：
          <br />
          021-39882683
          <br />
          <br />
          全国热线：
          <br />
          17721323870
          <br />
          <br />
          江浙沪热线：
          <br />
          13918812305
          <br />
          <br />
          公司邮箱：
          <br />
          puwo888&#64;163.com
          <br />
          <br />
          公司地址：
          <br />
          上海市奉贤区浦星公路9688弄6号6幢二层
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curCulture: "sm",
      titleList: [
        {
          label: "使命",
          value: "sm",
        },
        {
          label: "愿景",
          value: "yj",
        },
        {
          label: "价值观",
          value: "jzg",
        },
        {
          label: "宗旨",
          value: "zz",
        },
      ],
      cultureObj: {
        sm: {
          title: "我们的使命",
          des: "改善人类智能生活、推动科技发展、让生活更美好",
        },
        yj: {
          title: "我们的愿景",
          des: "成为智能科技的创新者和引领者",
        },
        jzg: {
          title: "我们的价值观",
          des: "诚信、责任、尊重、和谐、团队",
        },
        zz: {
          title: "我们的宗旨",
          des: "诚信经营，以客户为中心",
        },
      },
    };
  },
  computed: {
    showList() {
      return this.caseObj[this.caseType];
    },
  },
  methods: {
    chooseCul(val) {
      this.curCulture = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.group_1 {
  background-image: url(./assets/aboutus/aboutusbg1.png);
  background-size: cover;
  border: 1px solid rgba(151, 151, 151, 1);
}

.group_2 {
  background-color: rgba(24, 59, 124, 0.4);
  padding: 3.2vw 4vw 15.2vw 4vw;
}

.block_3 {
  margin: 16.8vw 5.6vw 0 0;
}

.text_1 {
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 4.26vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-right: 69.34vw;
}

.text_2 {
  width: 86.4vw;
  height: 17.6vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 8.8vw;
  margin-top: 1.34vw;
}

.block_4 {
  margin: 2.93vw 68vw 0 0;
}

.block_1 {
  background-color: rgba(255, 123, 52, 1);
  width: 24vw;
  height: 0.54vw;
}

.casetype_list_box {
  display: flex;
  width: 92vw;
  margin: 5vw auto;
  flex-wrap: wrap;
  .casetype_item_box {
    height: 10vw;
    min-width: 25vw;
    padding: 0 2vw;
    margin: 0 2vw 2vw 0;
    color: #fff;
    background-color: rgba(52, 57, 192, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no_selected {
    background-color: transparent;
    color: #222222;
  }
}

// 列表项
.list_3 {
  width: 92vw;
  margin: 0 auto;
  margin-top: 4vw;
}

.item_box {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.45);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 92vw;
  border: 1px solid rgba(244, 244, 244, 1);
  margin-bottom: 4vw;
}

.image_2 {
  width: 100%;
}

.text-group_18 {
  margin: 5.06vw 0vw 0 6.4vw;
}

.text_10 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.2vw;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.54vw;
  margin-right: 10.67vw;
}

.text_11 {
  width: 52.54vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  font-family: AlibabaPuHuiTi-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-top: 0.8vw;
  margin-bottom: 6vw;
}
// 新版css
.icon_box {
  width: 92vw;
  margin: 3.9vw auto;
  display: flex;
  flex-wrap: wrap;
  .icon_box_item {
    width: 49%;
    text-align: center;
    margin-bottom: 3.9vw;
    img {
      width: 10.14vw;
      height: 10.14vw;
    }
    .des_cls {
      color: #222222;
      margin-bottom: 0.5vw;
      font-size: 4.26vw;
      letter-spacing: 0.3999999761581421px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      white-space: nowrap;
      line-height: 5.87vw;
    }
    .des_cls1,
    .des_cls2 {
      color: rgba(102, 102, 102, 1);
      font-size: 3.2vw;
      letter-spacing: 0.29999998211860657px;
      font-weight: NaN;
      text-align: center;
      line-height: 4.54vw;
      margin-top: 1.6vw;
    }
  }
}

.group_12 {
  width: 44vw;
  margin: 5.4vw 48vw 0 4vw;
}

.group_5 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_8 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 24.54vw;
}

.text_9 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

.cooperation {
  width: 92vw;
  margin: 2vw auto 0;
  padding-bottom: 20vw;
  .c_left_box {
    width: 100%;
    .msg_line1 {
      overflow-wrap: break-word;
      color: #000;
      font-size: 4.26vw;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 5.87vw;
      margin: 2.4vw 0 0 0;
    }
    .msg_line2 {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      flex-wrap: wrap;
      .line2_item {
        width: 49%;
        overflow-wrap: break-word;
        font-family: Montserrat-Regular;
        text-align: left;
        color: #2d2d2d;
        font-size: 4.8vw;
        text-align: left;
        line-height: 6.67vw;
        margin-top: 3vw;
      }
    }
    .msg_line3,
    .msg_line4 {
      text-align: left;
      color: #2d2d2d;
      font-size: 4.8vw;
      text-align: left;
      line-height: 6.67vw;
      margin-top: 3vw;
      font-family: Montserrat-Regular;
    }
  }
  .c_right_box {
    width: 100%;
    .form_box {
      margin-top: 5vw;
      .right_line1 {
        // display: flex;
        // justify-content: space-between;
        .line1_item {
          width: 100%;
        }
      }
    }
  }
}

.el-button {
  width: 100%;
  height: 12vw;
  border-radius: 0;
  color: #fff;
  background: #3439c0;
  border-radius: 23px;
}
::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background-color: #f3f4f5;
}
::v-deep .el-textarea__inner {
  background-color: #f3f4f5;
}

// 新css

.intro_box {
  width: 92vw;
  margin: 3vw auto;
  .text-group_13 {
  }

  .text_12 {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 4.8vw;
    letter-spacing: 0.44999998807907104px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 6.67vw;
    margin-right: 32vw;
  }

  .text_13 {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 3.73vw;
    letter-spacing: 0.3499999940395355px;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 4.8vw;
    margin-top: 1.07vw;
  }

  .text-group_14 {
    width: 92vw;
    margin: 1vw auto;
  }

  .futitle {
    overflow-wrap: break-word;
    color: rgba(52, 57, 192, 1);
    font-size: 4.26vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    line-height: 5.87vw;
    margin: 1.73vw 0vw 0 0;
  }
  .paragraph_1 {
    letter-spacing: 0.2vw;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 3.73vw;
    text-align: left;
    line-height: 5.34vw;
    margin-top: 3.14vw;
  }
}
.zs_bg_box {
  width: 100vw;
  background-color: rgba(248, 250, 255, 1);
}
.zs_box {
  width: 92vw;
  padding: 3vw 0;
  margin: 0 auto;
  .section_5 {
    width: 38.4vw;
    margin-right: 53.87vw;
  }

  .block_1 {
    background-color: rgba(52, 57, 192, 1);
    width: 1.07vw;
    height: 9.87vw;
    margin: 0.8vw 0 1.33vw 0;
  }

  .text_15 {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 3.73vw;
    letter-spacing: 0.3499999940395355px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.34vw;
    margin-right: 18.94vw;
  }

  .text_16 {
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 5.33vw;
    text-transform: uppercase;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 6.4vw;
    margin-top: 0.27vw;
  }

  .zs_imgs {
    width: 100%;
    margin-top: 2vw;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        list-style: none;
        width: 49%;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 1%;
        padding: 4px 0;
        img {
          width: 165px;
          height: 234px;
          object-fit: cover;
          object-position: center;
          box-shadow: 0 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
}

.culture_box {
  width: 100vw;
  .box_11 {
    background-image: url(./assets/aboutus/aboutusbg2.png);
    background-size: cover;
    width: 100vw;
    // height: 31.25vw;
  }

  .group_17 {
    width: 21.25vw;
    margin: 10vw 0 0 4vw;
  }

  .group_8 {
    background-color: rgba(52, 57, 192, 1);
    width: 1.07vw;
    height: 9.87vw;
    margin: 0.8vw 0 1.33vw 0;
  }

  .text-wrapper_25 {
    margin-left: 2vw;
  }

  .text_33 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 3.73vw;
    letter-spacing: 0.3499999940395355px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.34vw;
    margin-right: 45.07vw;
  }

  .text_34 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 5.33vw;
    text-transform: uppercase;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 6.4vw;
    margin-top: 0.27vw;
  }

  .text-wrapper_26 {
    width: 19.22vw;
    margin: 8vw 0 4vw 4vw;
  }

  .text_36 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 3.73vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.34vw;
    margin-left: 4vw;
  }

  .text_37 {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    overflow-wrap: break-word;
    font-size: 3.73vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.34vw;
    margin-left: 4vw;
  }
  .selected {
    color: rgba(255, 123, 52, 1);
  }

  .text-wrapper_9 {
    width: 92vw;
    height: 40vw;
    background: linear-gradient(
      309deg,
      rgba(255, 255, 255, 0.4) 0%,
      #ffffff 100%
    );
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    // backdrop-filter: blur(10px);
    justify-content: flex-center;
    margin: 2.29vw 0 15vw 4vw;
  }

  .text_42 {
    overflow-wrap: break-word;
    color: #000;
    font-size: 4.26vw;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 5.87vw;
    margin-left: 6vw;
    margin-top: 6vw;
  }

  .text_43 {
    width: 80.8vw;
    height: 14.94vw;
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 5.33vw;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    line-height: 7.47vw;
    margin-top: 2.14vw;
    margin-left: 6vw;
  }
}

.contactus_box {
  position: relative;
  padding-bottom: 60vw;
  .group_23 {
    padding: 12.26vw 57.33vw 6.93vw 4vw;
  }

  .group_24 {
    width: 38.67vw;
  }

  .section_2 {
    background-color: rgba(52, 57, 192, 1);
    width: 1.07vw;
    height: 9.87vw;
    margin: 0.8vw 0 1.33vw 0;
  }

  .text_28 {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 3.73vw;
    letter-spacing: 0.3499999940395355px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.34vw;
    margin-right: 19.2vw;
  }

  .text_29 {
    overflow-wrap: break-word;
    color: rgba(45, 45, 45, 1);
    font-size: 5.33vw;
    text-transform: uppercase;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 6.4vw;
    margin-top: 0.27vw;
  }

  .group_14 {
    border-radius: 4px;
    background-image: url(./assets/aboutus/aboutusbg3.png);
    background-size: cover;
    margin-top: -0.26vw;
    padding: 45.6vw 16.26vw 65.6vw 18.66vw;
  }

  .text-wrapper_6 {
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 22px;
    padding: 2.93vw 7.73vw 2.93vw 4.8vw;
  }

  .text_30 {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 4.26vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 5.87vw;
  }

  .label_2 {
    width: 7.74vw;
    height: 12vw;
    align-self: center;
    margin-top: 1.07vw;
  }

  .text-wrapper_11 {
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1vw;
    width: 92vw;
    position: absolute;
    left: 4vw;
    top: 110.54vw;
    padding: 6.66vw 7.73vw 7.2vw 7.73vw;
  }

  .paragraph_4 {
    overflow-wrap: break-word;
    color: #2D2D2D;
    font-size: 4.26vw;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    line-height: 6.07vw;
  }
}
</style>