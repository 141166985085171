<template>
  <div class="box">
    <div class="product_banner">
      <div class="content_box">
        <div class="content_left">
          <div class="product_title">
            {{ deviceInfo.enname || "PWT-AQ-MFS" }}
            <br />
            {{ deviceInfo.name || "多功能空气质量传感器" }}
          </div>
          <div class="product_feature">
            {{
              deviceInfo.features ||
              "多合一监测｜高实用性｜易安装、接入灵活｜数据可视化"
            }}
          </div>
        </div>
        <div class="content_right">
          <el-carousel trigger="click" height="90vw">
            <el-carousel-item
              v-for="(item, index) in deviceInfo.lunImgs"
              :key="index"
            >
              <img loading="lazy" class="imgcls" :src="item.value" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div
      class="feature_box"
      v-for="(item, index) in deviceInfo.featureList"
      :key="index"
      :style="{
        'background-color': index % 2 == 1 ? '#072488' : '',
        'padding-bottom': index % 2 == 1 ? '5vw' : '',
      }"
    >
      <!-- :style="{ 'background-image': 'url(' + item.bgurl + ') !important' }" -->
      <div class="feature_content" :class="index % 2 == 1 ? 'reverse' : ''">
        <div class="feature_left">
          <div class="feature_title">{{ item.label }}</div>
          <div class="hg"></div>
          <div
            class="feature_des"
            :style="{ color: index % 2 == 1 ? '#fff' : '#333' }"
          >
            {{ item.des }}
          </div>
        </div>
        <div class="feature_right">
          <img :src="item.h5img" alt="" />
        </div>
      </div>
    </div>
    <div class="tabs_box">
      <div class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane
            lazy
            :label="item.label"
            :name="index + ''"
            v-for="(item, index) in deviceInfo.tabImgs"
            :key="index"
          >
            <img style="width: 92vw" :src="item.imgurl" alt="" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "0",
      product_cate: [
        {
          label: "软件产品",
          value: 1,
        },
        {
          label: "硬件产品",
          value: 2,
        },
      ],
      curdeviceVal: "",
      deviceInfo: {
        lunImgs: [],
      },
    };
  },
  mounted() {
    this.curdeviceVal = this.$route.params.deviceid;
    this.observeDoms(".feature_left");
    this.getDeviceInfo();
  },
  destroyed() {
    this.removeObserve(".feature_left");
  },
  methods: {
    async getDeviceInfo() {
      const res1 = await this.axios.get(
        "/jsondata/detaildata/" + this.curdeviceVal + ".json"
      );
      this.deviceInfo = res1.data;
    },
    backToProduct(value) {
      this.$router.push({
        name: "Products",
        params: {
          cateid: value,
        },
      });
    },
    back() {
      history.back();
      // this.$router.push({
      //   name: "Products",
      //   params: {
      //     cateid: value,
      //   },
      // });
    },
    removeObserve(selector) {
      let doms = document.querySelectorAll(selector);
      doms.forEach((dom) => {
        this.observer.unobserve(dom);
        this.observer.disconnent();
      });
    },
    observeDoms(selector) {
      let doms = document.querySelectorAll(selector);
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.style.opacity = 1;
          entries[0].target.style.transition = "0.5s";
          entries[0].target.style.transform = "translateY(0px)";
        }
      });
      doms.forEach((dom) => {
        this.observer.observe(dom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}

.product_banner {
  width: 100vw;
  height: 150vw;
  background-image: url(./assets/detail/test.png);
  background-size: cover;
  padding-top: 30vw;
  // margin-top: 15px;
  .content_box {
    height: 100%;
    width: 92%;
    margin: auto;
    .content_left {
      .product_title {
        font-size: 6.4vw;
        font-weight: 400;
        color: #2d2d2d;
        line-height: 1.3;
        margin: 0 0 20px 0;
      }
      .product_feature {
        font-size: 4.26vw;
        font-weight: normal;
        color: #3439c0;
        margin-bottom: 4vw;
      }
    }
    .content_right {
      width: 100%;
      position: relative;
      .el-carousel {
        width: 100%;
        margin: 0 auto;
      }
      .imgcls {
        width: 92vw;
      }
    }
  }
}
::v-deep .el-carousel__button {
  background-color: #000 !important;
  margin-top: -60px;
}

.feature_box {
  .feature_content {
    width: 92vw;
    margin: auto;

    .feature_left {
      // transform: translateY(100px);
      padding-top: 5vw;
      .feature_title {
        font-size: 5.63vw;
        margin: 0vw 0 2vw 0;
      }
      .hg {
        height: 1.5vw;
        width: 5.73vw;
        background-color: #0a7ad1;
        margin-bottom: 3vw;
      }
      .feature_des {
        width: 100%;
        font-size: 3.73vw;
        font-weight: lighter;
        line-height: 150%;
        font-family: "Open Sans" !important;
        color: #333;
      }
    }

    .feature_right {
      width: 92vw;
      img {
        width: 92vw;
        margin-top: 5vw;
      }
    }
  }
}

.reverse {
  // flex-direction: row-reverse;
  color: #fff;
}

.tabs_box {
  width: 100vw;
  background-color: #fff;
  padding: 30px 0;
  // min-height: 650px;
  .tabs {
    width: 92vw;
    margin: 0 auto;
  }
}
::v-deep .el-tabs__item {
  font-size: 22px;
}
::v-deep .el-tabs__nav-scroll {
  // margin-bottom: 20px;
}
</style>