<template>
  <div>
    <el-carousel height="157vw">
      <el-carousel-item v-for="item in 3" :key="item">
        <div class="section_14 flex-col" v-show="item == 1">
          <div class="box_31 flex-row">
            <div class="text-group_68 flex-col">
              <span class="text_47">成为智能科技的创新者和引领者</span>
              <span class="paragraph_2">
                建筑能源管理领域解决方案
                <br />
                建筑数字化孪生平台提供商
              </span>
            </div>
          </div>
          <div class="box_32 flex-row">
            <div class="block_7 flex-col"></div>
          </div>
          <div class="box_33 flex-row" @click="goShowPage('nyszhjjfa')">
            <div class="box_9 flex-row justify-between">
              <span class="text_48">MORE</span>
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/main/right.png"
              />
            </div>
          </div>
        </div>
        <img
          class="lun_img_cls"
          v-show="item == 2"
          src="./assets/main/lun2.png"
          alt=""
        />
        <img
          class="lun_img_cls"
          v-show="item == 3"
          src="./assets/main/lun3.png"
          alt=""
        />
      </el-carousel-item>
    </el-carousel>
    <div class="category_cls">
      <div
        class="section_1 flex-row justify-between"
        v-for="(item, index) in featureList"
        :key="index"
      >
        <div class="image-text_36 flex-row justify-between">
          <img class="image_8" referrerpolicy="no-referrer" :src="item.icon" />
          <div class="text-group_69 flex-col">
            <span class="text_1" @click="goShowPage(item.value)">{{
              item.label
            }}</span>
            <div class="text-wrapper_24 flex-row">
              <span
                class="text_50"
                v-for="(child, idx) in item.children"
                :key="idx"
                @click.stop="goProductCenterPage(item.value, child.value)"
              >
                {{ child.label }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字化解决方案tabs -->
    <div class="box_28 flex-row justify-between">
      <div class="section_5 flex-col"></div>
      <div class="text-group_57 flex-col">
        <span class="text_12">能源数字化解决方案</span>
        <span class="text_13">Digital&nbsp;energy&nbsp;solut</span>
      </div>
    </div>
    <div class="tabs_soft">
      <div
        class="tab_item_box"
        v-for="(item, index) in softList"
        :key="index"
        @click="chooseSoft(index, item.value)"
      >
        <div class="soft_item_box" :class="item.choose ? '' : 'no_selected'">
          <div>
            <img class="img_cls" :src="item.icon" alt="" />
          </div>
          <div class="soft_name">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案详情 -->
    <div class="section_9 flex-col">
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        :src="curSoftInfo.img"
      />
      <div class="text-group_58 flex-col">
        <span class="text_14">{{ curSoftInfo.intro }}</span>
        <span class="text_15">{{ curSoftInfo.title }}</span>
      </div>
      <span class="text_16">技术特点</span>
      <div class="section_33 flex-row justify-between">
        <div
          v-for="(item, index) in curSoftInfo.features"
          :key="index"
          class="image-text_30 flex-row"
        >
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/main/feature_point.png"
          />
          <span class="text-group_8">{{ item }}</span>
        </div>
      </div>

      <div class="section_36 flex-row justify-between">
        <!-- <div
          class="text-wrapper_4 flex-col"
          @click="goOhterWeb(curSoftInfo.url)"
        >
          <span class="text_17">立即体验</span>
        </div> -->
        <div
          class="section_10 flex-row justify-between"
          @click="goDetailPage(curSoftInfo.detailval)"
        >
          <span class="text_18">READ&nbsp;MORE</span>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/main/right_blue.png"
          />
        </div>
      </div>
    </div>
    <!-- 硬件 -->
    <div class="group_21 flex-col">
      <div class="group_22 flex-row justify-between">
        <div class="block_2 flex-col"></div>
        <div class="text-group_59 flex-col">
          <span class="text_19">能源物联网产品</span>
          <span class="text_20">Energy&nbsp;IoT&nbsp;products</span>
        </div>
      </div>
    </div>
    <div class="hard_list_box">
      <div
        class="hard_item_box"
        v-for="(item, index) in hardwareList"
        :key="index"
        @click="goDetailPageHard(item.value)"
      >
        <img class="img_cls" :src="item.img" alt="" />
        <div class="label_cls">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div
      class="block_12 flex-row justify-between"
      @click="goProductCenterPage('nywlwyjcp', 'yjall')"
    >
      <span class="text_54">READ&nbsp;MORE</span>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/main/right_blue.png"
      />
    </div>
    <!-- 行业应用 -->
    <div class="section_38 flex-row justify-between">
      <div class="box_15 flex-col"></div>
      <div class="text-group_70 flex-col">
        <span class="text_55">行业应用</span>
        <span class="text_56">INDUSTRY&nbsp;APPLICATION</span>
      </div>
    </div>
    <div class="list_3 flex-col">
      <div
        class="hy_item_box flex-col"
        v-for="(item, index) in hyyyList"
        :key="index"
        @click="goServiceCase(item.value)"
      >
        <img class="img_cls" :src="item.img" alt="" />
        <img class="img_mengban" src="./assets/main/bg3.png" alt="" />
        <div class="hy_remark">
          <div class="text_57" v-html="item.cntitle"></div>
          <div class="text_58" v-html="item.entitle"></div>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="section_39 flex-row justify-between">
      <div class="section_16 flex-col"></div>
      <div class="text-group_71 flex-col">
        <span class="text_59">上海扑沃智能科技有限公司</span>
        <span class="text_60">COMPANY&nbsp;PROFILE</span>
      </div>
    </div>
    <div class="text-group_72 flex-col">
      <span class="paragraph_3">
        敢为人先，追求卓越，
        <br />
        以创新为动力，以质量谋发展
      </span>
      <span class="paragraph_4">
        扑沃科技争做中国能源管理系统领先品牌，敢为人先，追求卓越，以创新为动力，以质量谋发展，服务“碳达峰、碳中和”战略决策，共筑中国梦-绿色化，扑沃将用科技的力量，还原自然的生态环境。
        <br />
        作为公共建筑能耗监测系统领域的高新技术企业，扑沃科技致力于提供能耗监测系统解决方案、相关产品及技术服务。公司具备专业的技术团队及多年的项目经验，具有强大的研发生产、销售及系统集成能力。&nbsp;产品涵盖了PWT-EMS系列能耗监测系统软件、PWT-DG系列物联网关(数据采集设备)，以及各类智能电力仪表、传感器。
      </span>
    </div>
    <div class="block_15 flex-row justify-between" @click="goaboutus">
      <span class="text_61">READ&nbsp;MORE</span>
      <img
        class="image_10"
        referrerpolicy="no-referrer"
        src="./assets/main/right_blue.png"
      />
    </div>
    <!-- 资质证书 -->
    <div class="group_27 flex-row justify-between">
      <div class="box_16 flex-col"></div>
      <div class="text-group_73 flex-col">
        <span class="text_64">资质证书</span>
        <span class="text_65">certificate</span>
      </div>
    </div>
    <div class="zs_list_box">
      <div class="zs_item_box" v-for="(item, index) in zsList" :key="index">
        <img class="img_cls" :src="item" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import szhjjfa from "./assets/main/szhjjfa.png";
import wlwyjcp from "./assets/main/wlwyjcp.png";
import fwal from "./assets/main/fwal.png";
// 数字化解决方案
import yffypt from "./assets/main/yffypt.png";
import dlywypt from "./assets/main/dlywypt.png";
import kqzljcpt from "./assets/main/kqzljcpt.png";
import zhnhypt from "./assets/main/zhnhypt.png";
import gfywypt from "./assets/main/gfywypt.png";
// 软件界面
import yffjm from "./assets/main/yffjm.png";
import dlywyptjm from "../../assets/index/dlywyptjm.png";
import kqzljcxtjm from "../../assets/index/kqzljcxtjm.png";
import zhnhyptjm from "../../assets/index/zhnhyptjm.png";
import gfywyptjm from "../../assets/index/gfywyptjm.png";
// 硬件展示缩略图
import dlybyj from "./assets/main/dlybyj.png";
import wjzhbhzz from "./assets/main/wjzhbhzz.png";
import kqzlcgqyj from "./assets/main/kqzlcgqyj.png";
import wlwgyj from "./assets/main/wlwgyj.png";
// 证书图片
import zs0 from "./assets/main/zs/高企业证书1.jpg";
import zs1 from "./assets/main/zs/DDS1852_00.jpg";
import zs2 from "./assets/main/zs/DDS1852Y_00.jpg";
import zs3 from "./assets/main/zs/DDS1852Y检测报告.jpg";
import zs4 from "./assets/main/zs/DTS1852、PWT803.830检测报告.jpg";
import zs5 from "./assets/main/zs/DTS1852_00.jpg";
import zs6 from "./assets/main/zs/DTS1852Y_00.jpg";
import zs7 from "./assets/main/zs/ISO质量管理体系认证证书.jpg";
import zs8 from "./assets/main/zs/PWD800Y_00.jpg";
// 行业图片
import hysyzht from "./assets/main/hysyzht.png";
import hyjyyl from "./assets/main/hyjyyl.png";
import hyzhyq from "./assets/main/hyzhyq.png";
import hyjdgy from "./assets/main/hyjdgy.png";
import hygkqy from "./assets/main/hygkqy.png";
import hygfcn from "./assets/main/hygfcn.png";

export default {
  data() {
    return {
      featureList: [
        {
          icon: szhjjfa,
          label: "数字化解决方案",
          value: "nyszhjjfa",
          children: [
            {
              label: "能耗管理",
              value: "rjnhgl",
            },
            {
              label: "电力监控",
              value: "rjdljk",
            },
            {
              label: "环境监测",
              value: "rjhjjc",
            },
            {
              label: "楼控及照明",
              value: "rjlkjzm",
            },
          ],
        },
        {
          icon: wlwyjcp,
          label: "物联网硬件产品",
          value: "nywlwyjcp",
          children: [
            {
              label: "电力仪表",
              value: "yjdlyb",
            },
            {
              label: "室内空气传感器",
              value: "yjsnkqcgq",
            },
            {
              label: "通讯设备",
              value: "yjtxsb",
            },
          ],
        },
        {
          icon: fwal,
          label: "服务案例",
          value: "fwal",
          children: [
            {
              label: "商业综合体",
              value: "syzht",
            },
            {
              label: "教育医疗",
              value: "jyyl",
            },
            {
              label: "工矿企业",
              value: "gkqy",
            },
            {
              label: "光伏储能",
              value: "gfcn",
            },
            {
              label: "智慧园区",
              value: "zhyq",
            },
            {
              label: "酒店公寓",
              value: "jdgy",
            },
          ],
        },
      ],
      softList: [
        {
          label: "预付费云平台",
          value: "yffypt",
          icon: yffypt,
          choose: true,
        },
        {
          label: "电力运维云平台",
          value: "dlywypt",
          icon: dlywypt,
          choose: false,
        },

        {
          label: "空气质量监测平台",
          value: "kqzljcpt",
          icon: kqzljcpt,
          choose: false,
        },
        {
          label: "综合能耗云平台",
          value: "zhnhypt",
          icon: zhnhypt,
          choose: false,
        },
        {
          label: "光伏运维云平台",
          value: "gfywypt",
          icon: gfywypt,
          choose: false,
        },
      ],
      cursoftVal: "yffypt",
      softinfoList: {
        yffypt: {
          img: yffjm,
          intro: "智能远程监控,终端数据可视化",
          title: "水电预付费/后付费管理系统",
          url: "http://prepay.puwo-tech.com/",
          detailval: "sdyhffglxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        dlywypt: {
          img: dlywyptjm,
          intro: "电力运维云平台",
          title: "电力运维云平台",
          url: "http://power.puwo-tech.com/",
          detailval: "dlywypt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        kqzljcpt: {
          img: kqzljcxtjm,
          intro: "智能远程监控,终端数据可视化",
          title: "空气质量监测平台",
          url: "http://aqms.puwo-tech.com/",
          detailval: "snkqzljcxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        zhnhypt: {
          img: zhnhyptjm,
          intro: "智能远程监控,终端数据可视化",
          title: "综合能耗云平台",
          url: "http://energy.puwo-tech.com/",
          detailval: "zhnhfwypt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        gfywypt: {
          img: gfywyptjm,
          intro: "智能远程监控,终端数据可视化",
          title: "光伏运维云平台",
          url: "http://pv.puwo-tech.com/",
          detailval: "gffdjkxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
          ],
        },
      },
      hardwareList: [
        {
          label: "电力仪表",
          value: "sxdcyb",
          img: dlybyj,
        },
        {
          label: "综保",
          value: "600xwjzhbhzz",
          img: wjzhbhzz,
        },
        {
          label: "空气质量传感器",
          value: "dgnkqzlcgq",
          img: kqzlcgqyj,
        },
        {
          label: "物联网关",
          value: "dgc4swlwg",
          img: wlwgyj,
        },
      ],
      zsList: [zs0, zs1, zs2, zs3, zs4, zs5, zs6, zs7, zs8],
      hyyyList: [
        {
          cntitle: "商业综合体",
          entitle: "Commercial&nbsp;complex",
          value: "syzht",
          img: hysyzht,
        },
        {
          cntitle: "教育医疗",
          entitle: "Education&nbsp;and&nbsp;healthcare",
          value: "jyyl",
          img: hyjyyl,
        },
        {
          cntitle: "智慧园区",
          entitle: "Smart&nbsp;Park",
          value: "zhyq",
          img: hyzhyq,
        },
        {
          cntitle: "酒店公寓",
          entitle: "Hotel&nbsp;apartment",
          value: "jdgy",
          img: hyjdgy,
        },
        {
          cntitle: "工矿企业",
          entitle: "Industrial&nbsp;and&nbsp;mining&nbsp;enterprises",
          value: "gkqy",
          img: hygkqy,
        },
        {
          cntitle: "光伏储能",
          entitle: "Photovoltaic&nbsp;energy&nbsp;storage",
          value: "gfcn",
          img: hygfcn,
        },
      ],
    };
  },
  computed: {
    curSoftInfo() {
      return this.softinfoList[this.cursoftVal];
    },
  },
  methods: {
    goaboutus() {
      this.$router.push({
        name: "H5Aboutus",
      });
    },
    goServiceCase(caseType) {
      this.$router.push({
        name: "H5ServiceCase",
        params: {
          caseType,
        },
      });
    },
    goDetailPageHard(deviceid) {
      this.$router.push({
        name: "H5ProductDetail",
        params: {
          deviceid,
        },
      });
    },
    goDetailPage(deviceid) {
      this.$router.push({
        name: "H5SoftwareDetail",
        params: {
          deviceid,
        },
      });
    },
    goOhterWeb(url) {
      window.open(url);
    },
    goProductCenterPage(val, val1) {
      if (val == "nyszhjjfa" || val == "nywlwyjcp") {
        this.$router.push({
          name: "H5Product",
          params: {
            curFatherType: val,
            curSonType: val1,
          },
        });
      } else if (val == "fwal") {
        this.$router.push({
          name: "H5ServiceCase",
          params: {
            caseType: val1,
          },
        });
      }
    },
    goShowPage(val) {
      if (val == "nyszhjjfa" || val == "nywlwyjcp") {
        this.$router.push({
          name: "H5Product",
          params: {
            curFatherType: val,
            curSonType: val == "nyszhjjfa" ? "rjall" : "yjall",
          },
        });
      } else if (val == "fwal") {
        this.$router.push({
          name: "H5ServiceCase",
        });
      }
    },
    chooseSoft(index, val) {
      this.cursoftVal = val;
      this.softList[index].choose = true;
      this.softList.map((item, idx) => {
        if (idx == index) {
          return;
        } else {
          item.choose = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__indicators--horizontal {
  bottom: 15%;
  left: 31%;
  //   width: 100vw;
}
::v-deep .el-carousel__button {
  width: 12vw;
}
.section_14 {
  background-image: url(./assets/main/bg1.png);
  background-size: cover;
  height: 157vw;
  width: 100vw;
  padding: 3.2vw 4vw 25.33vw 4vw;
  position: relative;
}

.box_31 {
  margin: 27.73vw 8.8vw 0 6.4vw;
}

.text_47 {
  height: 9.87vw;
  overflow-wrap: break-word;
  color: rgba(142, 155, 180, 1);
  font-size: 3.73vw;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  line-height: 9.6vw;
  margin-right: 24.54vw;
}

.paragraph_2 {
  width: 76.8vw;
  height: 20.27vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  font-family: OPPOSans-H;
  font-weight: NaN;
  text-align: left;
  line-height: 10.14vw;
}

.box_32 {
  margin: 6.66vw 53.6vw 0 6.4vw;
}

.block_7 {
  background-color: rgba(255, 123, 52, 1);
  width: 32vw;
  height: 0.54vw;
}

.box_33 {
  margin: 22.13vw 50.93vw 0 6.4vw;
}

.box_9 {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  width: 34.67vw;
  border: 0.6941176470588235px solid rgba(255, 255, 255, 1);
  padding: 2.13vw 4vw 2.13vw 3.73vw;
}

.text_48 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.73vw;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.8vw;
}

.image_7 {
  width: 8.27vw;
  height: 3.2vw;
  margin: 0.8vw 0 0.8vw 0;
}

.section_1 {
  width: 92.8vw;
  height: 30.14vw;
  background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
  box-shadow: 6px 6px 15px 0px rgba(55, 99, 170, 0.1);
  border-radius: 3px;
  padding: 5.6vw 6.13vw 4.8vw 5.06vw;
  margin: 2vw auto;
  margin-bottom: 4vw;
}

.image-text_36 {
  width: 64vw;
}

.image_8 {
  width: 19.47vw;
  height: 19.47vw;
}

.text-group_69 {
  margin: 1.6vw 0 4vw 5vw;
  width: 58vw;
}

.text_49 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4125000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 6.14vw;
}

.text-wrapper_24 {
  width: 100%;
  margin-top: 2.2vw;
  flex-wrap: wrap;
}

.text_50 {
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 2.66vw;
  letter-spacing: 0.26249998807907104px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4vw;
  margin-right: 2.5vw;
  margin-top: 1vw;
}

.text_1 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4125000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 6.14vw;
  margin-right: 3.2vw;
}

.category_cls {
  margin-top: -17vw;
  position: relative;
  z-index: 12;
}

// 数字化解决方案tabs
.box_28 {
  width: 68.27vw;
  margin: 12.26vw 27.73vw 0 4vw;
}

.section_5 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_12 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 29.6vw;
}

.text_13 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}
// tabs box css
.tabs_soft {
  width: 92vw;
  margin: 5vw auto;
  display: flex;
  overflow: auto;
  border-bottom: 0.3vw solid #ebebeb;
  .tab_item_box {
    margin-right: 5vw;
    .soft_item_box {
      padding: 0 3vw 3vw 0.5vw;
      border-bottom: 0.5vw solid #3439c0;
    }
    .no_selected {
      border-bottom: 0;
    }
    .soft_name {
      font-size: 3.7vw;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      margin-top: 1.2vw;
    }
  }
  .img_cls {
    width: 10.14vw;
    height: 10.14vw;
  }
}

.tabs_soft::-webkit-scrollbar {
  display: none;
}

.section_9 {
  background-color: rgba(255, 255, 255, 1);
  padding: 5vw 0 9.33vw 0;
}

.image_3 {
  width: 92vw;
  height: 37vw;
  margin: 0 auto;
}

.text-group_58 {
  margin: 5.53vw 16vw 0 4vw;
}

.text_14 {
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 30.14vw;
}

.text_15 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 6.4vw;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 8.8vw;
  margin-top: 2.14vw;
}

.text_16 {
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin: 6vw 0 0 4vw;
}

.section_33 {
  width: 79.47vw;
  margin: 4vw 16.53vw 0 4vw;
  display: flex;
  flex-wrap: wrap;
}

.image-text_30 {
  width: 49%;
  margin-bottom: 2vw;
}

.thumbnail_1 {
  width: 4vw;
  height: 4vw;
  margin: 0.8vw 2.5vw 0.53vw 0;
}

.text-group_8 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.6vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
}

.section_36 {
  width: 92vw;
  align-self: center;
  margin-top: 10.94vw;
}

.text-wrapper_4 {
  background-color: rgba(52, 57, 192, 1);
  border-radius: 18px;
  padding: 2.13vw 10.93vw 2.13vw 10.93vw;
}

.text_17 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
}

.section_10 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  width: 45.34vw;
  border: 1px solid rgba(52, 57, 192, 1);
  padding: 1.86vw 5.86vw 1.86vw 4.8vw;
}

.text_18 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
}

.image_4 {
  width: 5.87vw;
  height: 1.6vw;
  margin: 1.6vw 0 2.13vw 0;
}

.group_21 {
  position: relative;
  padding: 7.06vw 0 1vw 4vw;
}

.group_22 {
  width: 68.8vw;
}

.block_2 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_19 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 37.87vw;
}

.text_20 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

// 物联网产品list css
.hard_list_box {
  display: flex;
  overflow: auto;
  width: 92vw;
  margin: 0 auto 6vw;
  padding-top: 3vw;
  padding-bottom: 3vw;
  .hard_item_box {
    width: 69vw;
    margin-right: 5vw;
    background: #ffffff;
    box-shadow: 5px 2px 12px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  .hard_item_box:last-child {
    margin-right: 0;
  }
  .img_cls {
    width: 69vw;
  }
  .label_cls {
    width: 69vw;
    height: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
  }
}
.hard_list_box::-webkit-scrollbar {
  display: none;
}

.block_12 {
  border-radius: 18px;
  width: 45.34vw;
  border: 1px solid rgba(52, 57, 192, 1);
  margin: 5vw 47.73vw 4vw 4vw;
  padding: 1.86vw 5.86vw 1.86vw 4.8vw;
}

.text_54 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
}

.image_9 {
  width: 5.87vw;
  height: 1.6vw;
  margin: 1.6vw 0 2.13vw 0;
}

// 资质证书
.group_27 {
  width: 38.4vw;
  margin: 7vw 57.6vw 0 4vw;
}

.box_16 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_64 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 18.94vw;
}

.text_65 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

.zs_list_box {
  display: flex;
  overflow: auto;
  width: 92vw;
  margin: 0 auto 6vw;
  padding-top: 3vw;
  padding-bottom: 3vw;
  .zs_item_box {
    width: 50vw;
    margin-right: 5vw;
    background: #ffffff;
    box-shadow: 5px 2px 12px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zs_item_box:last-child {
    margin-right: 0;
  }
  .img_cls {
    width: 100%;
  }
}
.zs_list_box::-webkit-scrollbar {
  display: none;
}

// 行业应用
.section_38 {
  width: 69.34vw;
  margin: 12.8vw 0vw 0 4vw;
}

.box_15 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_55 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 49.87vw;
}

.text_56 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

.list_3 {
  margin: 4.53vw 1.06vw 1vw 0;
}

.hy_item_box {
  width: 92vw;
  height: 45vw;
  margin: 2vw auto;
  background: url(./assets/main/bg3.png) 100% no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-radius: 1vw;
  .img_cls {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
  }
  .img_mengban {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 1vw;
  }
  .hy_remark {
    position: absolute;
    z-index: 9;
    bottom: 6vw;
    left: 6vw;
  }
}

.text_57 {
  width: 21.34vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 4.26vw;
  font-family: DingTalk-JinBuTi;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.07vw;
}

.text_58 {
  width: 62.4vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.73vw;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.8vw;
  margin-top: 1.07vw;
}

// 公司简介
.section_39 {
  width: 56.27vw;
  margin: 12.8vw 0vw 0 4vw;
}

.section_16 {
  background-color: rgba(52, 57, 192, 1);
  width: 1.07vw;
  height: 9.87vw;
  margin: 0.8vw 0 1.33vw 0;
}

.text_59 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 6.14vw;
}

.text_60 {
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 5.33vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 6.4vw;
  margin-top: 0.27vw;
}

.text-group_72 {
  margin: 6vw 0 0 4vw;
}

.paragraph_3 {
  width: 64vw;
  height: 13.34vw;
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 4.8vw;
  letter-spacing: 0.44999998807907104px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 6.67vw;
  margin-right: 28vw;
}

.paragraph_4 {
  width: 92vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 3.73vw;
  text-align: left;
  margin-top: 4vw;
  letter-spacing: 0.25vw;
  line-height: 6vw;
}
.block_15 {
  border-radius: 18px;
  width: 45.34vw;
  border: 1px solid rgba(52, 57, 192, 1);
  margin: 6vw 0.73vw 0 4vw;
  padding: 1.86vw 5.86vw 1.86vw 4.8vw;
}

.text_61 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.73vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
}

.image_10 {
  width: 5.87vw;
  height: 1.6vw;
  margin: 1.6vw 0 2.13vw 0;
}

.lun_img_cls{
  width: 100vw;
}
</style>