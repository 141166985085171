<template>
  <div class="box">
    <div class="product_banner">
      <div class="content_box">
        <div class="content_left">
          <div class="product_feature">
            {{ deviceInfo.features || "---" }}
          </div>
          <div class="product_title">
            {{ deviceInfo.name || "---" }}
          </div>
        </div>
        <div class="content_right">
          <div class="product_img_box">
            <img class="imgcls" :src="deviceInfo.lunImgs.value" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="pages_box">
      <div class="software_des">
        {{ deviceInfo.sysDes }}
      </div>
    </div>
    <div class="tech_box">
      <div class="title_box">
        <div class="tech_title">技术特点</div>
        <div class="tech_des">强大的研发实力，只要你需要，我们就可以。</div>
      </div>
      <div
        class="page_cls"
        v-for="(item, index) in deviceInfo.pageList"
        :key="index"
      >
        <div class="img_cls">
          <img :src="item.value" alt="" />
        </div>
        <div class="page_des_box">
          <div class="des_box">
            <div class="des_title">{{ item.title1 || "全能耗在线监测" }}</div>
            <div class="des_text">
              {{
                item.des1 ||
                "各类终端设备对建筑电、水、气等各类能耗实时上传至系统，实现能耗数据的采集、汇总及多维度分析。"
              }}
            </div>
          </div>
          <div class="des_box">
            <div class="des_title">{{ item.title2 || "可视化数据呈现" }}</div>
            <div class="des_text">
              {{
                item.des2 ||
                "采用饼图、线状图、柱状图、雷达图等多样化的图表形式，以可视化方式综合呈现能耗数据，方便用户从各种维度观察和分析数据。"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      curdeviceVal: "",
      deviceInfo: {
        lunImgs: {},
      },
    };
  },
  mounted() {
    this.curdeviceVal = this.$route.params.deviceid;
    this.getDeviceInfo();
  },

  methods: {
    async getDeviceInfo() {
      const res1 = await this.axios.get(
        "/jsondata/detaildata/" + this.curdeviceVal + ".json"
      );
      this.deviceInfo = res1.data;
    },
    backToProduct(value) {
      this.$router.push({
        name: "Products",
        params: {
          cateid: value,
        },
      });
    },
    back() {
      history.back();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}

.product_banner {
  width: 100vw;
  height: 120vw;
  background-image: url(./assets/detail/test.png);
  background-size: cover;
  padding-top: 33vw;
  // margin-top: 15px;
  .content_box {
    height: 100%;
    width: 92%;
    margin: auto;
    .content_left {
      .product_title {
        font-size: 6.4vw;
        font-weight: 400;
        color: #2d2d2d;
        line-height: 1.3;
        margin: 0 0 20px 0;
      }
      .product_feature {
        font-size: 4.26vw;
        font-weight: normal;
        color: #3439c0;
        margin-bottom: 4vw;
      }
    }
    .content_right {
      width: 100vw;
      position: relative;
      .imgcls {
        width: 92vw;
        // height: 353px;
      }
    }
  }
}
::v-deep .el-carousel__button {
  background-color: #000 !important;
  margin-top: -60px;
}

.reverse {
  flex-direction: row-reverse;
  color: #fff;
  // background: #fff !important;
}

.tabs_box {
  width: 100%;
  background-color: #fff;
  padding: 30px 0;
  .tabs {
    width: 80%;
    margin: 0 auto;
  }
}
::v-deep .el-tabs__item {
  font-size: 22px;
}
::v-deep .el-tabs__nav-scroll {
  // margin-bottom: 20px;
}

// 悬浮返回
.fix_box1 {
  display: flex;
  position: fixed;
  top: 110px;
  // left: -120px;
  z-index: 999;
  cursor: pointer;
  transition: 0.2s;
}
.fix_box1:hover {
  left: 0;
  transition: 0.2s;
}
.fix_cls {
  // background-color: rgba(0, 0, 0, 0.4) !important;
  // border-radius: 0 4px 4px 0;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
}
.fix_cls:hover {
  background-color: #fff;
  color: #004899;
}
.fix_text {
  width: 100px;
  // height: 74px;
  // line-height: 74px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4) !important;
  font-size: 13px;
  color: #fff;
  transition: all 0.2s;
  .fix_item {
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-top: 1px solid #ccc;
  }
  .fix_item:hover {
    background-color: #fff;
    color: #004899;
  }
}

.pages_box {
  width: 100vw;
  background-color: #fff;
  padding: 6vw 0;
  box-sizing: border-box;
  .software_des {
    // text-indent: 2em;
    width: 92vw;
    margin: 0 auto;
    font-size: 3.73vw;
    line-height: 190%;
    font-family: "Open Sans" !important;
    color: #666;
  }
  .el-carousel {
    width: 900px;
    margin: 0 auto;
  }
}
// 技术特点
.tech_box {
  width: 100vw;
  background-color: #fff;
  padding: 30px 0;
  box-sizing: border-box;
  .title_box {
    width: 92vw;
    margin: 0 auto;
    color: #333;
    .tech_title {
      font-size: 5.86vw;
    }
    .tech_des {
      font-size: 3.73vw;
      margin-top: 8px;
    }
  }
}
.page_cls {
  width: 100vw;
  background-color: #fff;
  padding: 7vw 0 10vw 0;
  .img_cls {
    width: 92vw;
    max-height: 550px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 92vw;
    }
  }

  // 描述
  .page_des_box {
    width: 92vw;
    margin: 0 auto;
    .des_box {
      width: 100%;
      margin: 4vw 0;
      .des_title {
        font-size: 4.55vw;
        font-weight: normal;
        margin-bottom: 2vw;
        color: #3439C0;
      }
      .des_text {
        font-size: 3.73vw;
        color: #333;
        line-height: 1.8;
      }
    }
  }
}
.page_cls:nth-child(2n + 1) {
  background-color: #f5f5f5;
}
</style>