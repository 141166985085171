<template>
  <div>
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <div class="block_3 flex-row">
          <div class="text-group_17 flex-col">
            <span class="text_1">产品中心</span>
            <span class="text_2"
              >建筑能源管理领域解决方案&nbsp;，建筑数字化孪生平台提供商</span
            >
          </div>
        </div>
        <div class="block_4 flex-row"><div class="block_1 flex-col"></div></div>
      </div>
    </div>

    <div class="tabs_soft">
      <div
        class="tab_item_box"
        v-for="(item, index) in featureList"
        :key="index"
        @click="chooseFathertype(index, item.value)"
      >
        <div
          class="soft_item_box"
          :class="curFatherType == item.value ? '' : 'no_selected'"
        >
          <div class="soft_name">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="sontype_list_box">
      <div
        class="sontype_item_box"
        :class="curSonType == item.value ? '' : 'no_selected'"
        v-for="(item, index) in cursontypeList"
        @click="chooseSontype(index, item.value)"
        :key="item.value"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="list_3 flex-col">
      <div
        class="image-text_1 flex-row justify-between"
        v-for="(item, index) in showList"
        :key="index"
        @click="goDetailPage(item.value)"
      >
        <img class="image_2" referrerpolicy="no-referrer" :src="item.imgurl" />
        <div class="text-group_18 flex-col">
          <div class="text_10" v-html="item.des"></div>
          <div class="text_11" v-html="item.label"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      featureList: [
        {
          label: "数字化解决方案",
          value: "nyszhjjfa",
          choose: true,
          children: [
            {
              label: "全部",
              value: "rjall",
              choose: true,
            },
            {
              label: "能耗管理",
              value: "rjnhgl",
              choose: false,
            },
            {
              label: "电力监控",
              value: "rjdljk",
              choose: false,
            },
            {
              label: "环境监测",
              value: "rjhjjc",
              choose: false,
            },
            {
              label: "楼控及照明",
              value: "rjlkjzm",
              choose: false,
            },
          ],
        },
        {
          label: "物联网硬件产品",
          value: "nywlwyjcp",
          choose: false,
          children: [
            {
              label: "全部",
              value: "yjall",
              choose: true,
            },
            {
              label: "电力仪表",
              value: "yjdlyb",
              choose: false,
            },
            {
              label: "室内空气传感器",
              value: "yjsnkqcgq",
              choose: false,
            },
            {
              label: "通讯设备",
              value: "yjtxsb",
              choose: false,
            },
          ],
        },
      ],
      curFatherType: "nyszhjjfa",
      curSonType: "rjall",
      softProductList: {},
      hardwareList: {},
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.params) != "{}") {
      let tempfathertype = sessionStorage.getItem("curFatherType");
      let tempsontype = sessionStorage.getItem("curSonType");
      this.curFatherType = tempfathertype || this.$route.params.curFatherType;
      this.curSonType = tempsontype || this.$route.params.curSonType;
      sessionStorage.removeItem("curFatherType");
      sessionStorage.removeItem("curSonType");
    }
    this.getIndexInfo();
  },
  computed: {
    cursontypeList() {
      return this.featureList.filter(
        (item) => item.value == this.curFatherType
      )[0].children;
    },
    showList() {
      if (this.curFatherType == "nyszhjjfa") {
        return this.softProductList[this.curSonType];
      } else {
        return this.hardwareList[this.curSonType];
      }
    },
  },
  methods: {
    async getIndexInfo() {
      const res1 = await this.axios.get("/jsondata/h5data/h5cpzx.json");
      this.softProductList = res1.data.softProductList;
      this.hardwareList = res1.data.hardwareList;
    },
    chooseFathertype(index, val) {
      sessionStorage.setItem("curFatherType", val);
      this.curFatherType = val;
      if (this.curFatherType == "nyszhjjfa") {
        this.curSonType = "rjall";
        sessionStorage.setItem("curSonType", "rjall");
      } else {
        this.curSonType = "yjall";
        sessionStorage.setItem("curSonType", "yjall");
      }
      this.featureList[index].choose = true;
      this.featureList.map((item, idx) => {
        if (idx == index) {
          return;
        } else {
          item.choose = false;
        }
      });
    },
    chooseSontype(index, val) {
      this.curSonType = val;
      sessionStorage.setItem("curFatherType", this.curFatherType);
      sessionStorage.setItem("curSonType", val);
    },
    goDetailPage(deviceid) {
      if (this.curFatherType == "nyszhjjfa") {
        this.$router.push({
          name: "H5SoftwareDetail",
          params: {
            deviceid,
          },
        });
      } else {
        this.$router.push({
          name: "H5ProductDetail",
          params: {
            deviceid,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group_1 {
  background-image: url(./assets/product/product_center.png);
  border: 1px solid rgba(151, 151, 151, 1);
}

.group_2 {
  background-color: rgba(24, 59, 124, 0.55);
  padding: 3.2vw 4vw 15.2vw 4vw;
}

.block_3 {
  margin: 16.8vw 5.6vw 0 0;
}

.text_1 {
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 4.26vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-right: 69.34vw;
}

.text_2 {
  width: 86.4vw;
  height: 17.6vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 8.8vw;
  margin-top: 1.34vw;
}

.block_4 {
  margin: 2.93vw 68vw 0 0;
}

.block_1 {
  background-color: rgba(255, 123, 52, 1);
  width: 24vw;
  height: 0.54vw;
}

// tabs
// tabs box css
.tabs_soft {
  width: 92vw;
  margin: 3vw auto;
  display: flex;
  overflow: auto;
  border-bottom: 0.3vw solid #ebebeb;
  .tab_item_box {
    margin-right: 5vw;
    .soft_item_box {
      padding: 0 3vw 3vw 0.5vw;
      color: #3439c0;
      border-bottom: 0.5vw solid #3439c0;
    }
    .no_selected {
      color: #000;
      border-bottom: 0;
    }
    .soft_name {
      font-size: 4.26vw;
      font-family: OPPOSans-M;
      font-weight: 500;
      line-height: 5.87vw;
      margin-top: 1.2vw;
    }
  }
}

.tabs_soft::-webkit-scrollbar {
  display: none;
}

.sontype_list_box {
  display: flex;
  width: 92vw;
  margin: 0 auto;
  flex-wrap: wrap;
  .sontype_item_box {
    height: 10vw;
    min-width: 25vw;
    padding: 0 2vw;
    margin: 0 2vw 2vw 0;
    color: #fff;
    background-color: rgba(52, 57, 192, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no_selected {
    background-color: transparent;
    color: #222222;
  }
}

// 列表项
.list_3 {
  width: 92vw;
  margin: 0 auto;
  margin-top: 4vw;
}

.image-text_1 {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 92vw;
  border: 1px solid rgba(244, 244, 244, 1);
  margin-bottom: 4vw;
  padding: 2.66vw 5.33vw 2.66vw 2.13vw;
}

.image_2 {
  width: 29.87vw;
  height: 22.4vw;
}

.text-group_18 {
  margin: 4.26vw 0 6.93vw 2vw;
}

.text_10 {
  width: 52.54vw;
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 3.2vw;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.54vw;
  margin-right: 10.67vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text_11 {
  width: 52.54vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 4.26vw;
  font-family: AlibabaPuHuiTi-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
  margin-top: 0.8vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>